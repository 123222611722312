<template>
	<div id="trainingBase">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">实训基地</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index == teacherIndex ? 'active' : ''"
						v-for="(item, index) in teacherMeun"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>
				
			</div>
			<div class="content">
				<div class="u-f">
					<div class="right">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{ total }}条数据
								<!-- <span style="color: #007AFF;">已选择0条</span> -->
							</div>
							<div class="u-f-item" style="padding-right: 10px;">
								<el-button size="small" @click="addroom=true">添加检查</el-button>
								<el-button size="small" type="primary" @click="exportstrainingBaseCheck">导出</el-button>
							</div>
						</div>
						<el-table
							:data="List"
							height="64vh"
							ref="multipleTable"
							tooltip-effect="dark"
							style="width: 93vw;border: 1px solid #EEEEEE;"
							key="1"
						>
							<el-table-column type="index" label="序号" width="80" fixed align="center"></el-table-column>
							<el-table-column prop="training_room_name" width="120" label="实训室名称" fixed align="center"></el-table-column>
							<el-table-column prop="check_time" width="150" label="检查时间" fixed align="center"></el-table-column>
							<el-table-column prop="hidden_danger_content" label="隐患内容" fixed align="center"></el-table-column>
							<el-table-column label="整改建议" prop="recommendations" fixed align="center"></el-table-column>
							<el-table-column prop="remark" label="备注" fixed align="center"></el-table-column>
							<el-table-column label="操作" width="150" fixed="right" align="center">
								<template slot-scope="scope">
									<el-button @click="openDialog(scope.row)" type="text" size="small">编辑</el-button>
									<el-button @click="del_room(scope.row.id)" type="text" size="small">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-item u-f-right" style="padding: 15px 0;">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</div>
			</div>
		
			<!-- 添加房间 -->
			<el-dialog :title="title" :visible.sync="addroom" width="500px" :before-close="handleClose">
				<div class="dialogBody">
					<div class="edit-box">
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 100px;">
								<span>*</span>
								实训基地
							</div>
							<el-select v-model="training_base_id" placeholder="请选择实训基地" style="width: 70%;" filterable>
								<el-option v-for="(item,index) in BaseList" :key="index" :label="item.training_room_name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 100px;">
								<span>*</span>
								检查时间
							</div>
							<el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="check_time" type="datetime" placeholder="选择检查时间" style="width: 70%;"></el-date-picker>
						</div>
						<div class="u-f input-box">
							<div class="input-lebal" style="text-align: right;width: 100px;margin-top: 10px;">
								<span>*</span>
								隐患内容
							</div>
							<el-input v-model="hidden_danger_content" type="textarea" :rows="3" placeholder="请输入隐患内容" style="width: 70%;"></el-input>
						</div>
						<div class="u-f input-box">
							<div class="input-lebal" style="text-align: right;width: 100px;margin-top: 10px;">
								<span>*</span>
								整改建议
							</div>
							<el-input v-model="recommendations" type="textarea" :rows="3" placeholder="请输入整改建议" style="width: 70%;"></el-input>
						</div>
						<div class="u-f input-box">
							<div class="input-lebal" style="text-align: right;width: 100px;margin-top: 10px;">
								备注
							</div>
							<el-input v-model="remark" type="textarea" :rows="3" placeholder="请输入备注" style="width: 70%;"></el-input>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
			
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			List: [],
			page: 1,
			limit: 10,
			total: 0,
			addroom: false,
			editroom:false,
			type:1,
			title:'添加检查',
			updateVisible:false,
			teacherMeun: [
				{ name: '实训室列表',id:1},
				{ name: '实训室检查',id:2}
			],
			teacherIndex: 1,
			BaseList:[],
			hidden_danger_content:'',
			recommendations:'',
			training_base_id:'',
			check_time:'',
			recommendations:'',
			remark:''
		};
	},
	mounted() {
		this.getInfo()
		this.trainingBaseList()
	},
	methods: {
		twacherTap(index){
			this.teacherIndex = index;
			if(index==0){
				this.$router.push('/trainingBase')
			}else{
				this.$router.push('/examine')
			}
		},
		// 获取实训基地列表
		trainingBaseList(){
			this.$api.setting.trainingBaseList({}).then(res=>{
				if(res.data.code==1){
					this.BaseList = res.data.data.rows;
				}
			})
		},
		// 获取检查列表
		getInfo(){
			this.$api.setting.trainingBaseCheckList({
				page:this.page,
				limit:this.limit
			}).then(res=>{
				if(res.data.code==1){
					this.List = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
			
		},
		handleClose(done) {
			this.init()
			done();
		},
		closeShow(){
			this.init()
			this.addroom = false;
		},
		// 打开弹窗
		openDialog(item) {
			this.training_base_id = item.training_base_id;
			this.check_time = item.check_time;
			this.hidden_danger_content = item.hidden_danger_content;	
			this.recommendations = item.recommendations;
			this.remark = item.remark;
			this.edit_id = item.id;
			this.type = 2;
			this.title = '编辑检查';
			this.addroom = true;
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getInfo()
		},
		// 添加房间
		submit(){
			if(this.type==1){
				this.addTrainingBase()
			}else if(this.type==2){
				this.editTrainingBase()
			}
		},
		init(){
			this.training_base_id = '';
			this.check_time = '';
			this.hidden_danger_content = '';
			this.recommendations = '';
			this.remark = '';
			this.type = 1;
			this.title = '添加检查';
		},
		// 添加实训基地
		addTrainingBase(){
			if(!this.training_base_id){return this.$message.error('请选择要检查的实训室')}
			if(!this.check_time){return this.$message.error('请选择检查时间')}
			if(!this.hidden_danger_content){return this.$message.error('请输入隐患内容')}
			if(!this.recommendations){return this.$message.error('请输入整改建议')}
			let data = {
				training_base_id:this.training_base_id, 	
				check_time:this.check_time,
				hidden_danger_content:this.hidden_danger_content,	
				recommendations:this.recommendations,
				remark:this.remark
			}
			this.$api.setting.addtrainingBaseCheck(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功')
					this.init()
					this.addroom = false;
					this.getInfo()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 编辑实训基地
		editTrainingBase(){
			if(!this.training_base_id){return this.$message.error('请选择要检查的实训室')}
			if(!this.check_time){return this.$message.error('请选择检查时间')}
			if(!this.hidden_danger_content){return this.$message.error('请输入隐患内容')}
			if(!this.recommendations){return this.$message.error('请输入整改建议')}
			let data = {
				id:this.edit_id,
				training_base_id:this.training_base_id,
				check_time:this.check_time,
				hidden_danger_content:this.hidden_danger_content,	
				recommendations:this.recommendations,
				remark:this.remark
			}
			this.$api.setting.edittrainingBaseCheck(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('编辑成功')
					this.init()
					this.addroom = false;
					this.getInfo()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 删除实训基地
		del_room(id){
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.deltrainingBaseCheck({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getInfo();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		exportstrainingBaseCheck(){
			this.$api.setting
				.exportstrainingBaseCheck({})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('导出成功');
						location.href = res.data.data.url;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		}
	}
};
</script>

<style lang="scss">
#trainingBase {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 70vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 74vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 70vh;
			width: 100%;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 0 35px 20px;
		.dialogBody{
			overflow-y: auto;
			max-height: 500px;
			.edit-box {
				.input-box {
					margin-top: 35px;
					padding-left: 15px;
					.input-lebal {
						color: #aeb5b8;
						margin-right: 10px;
						span {
							color: #ff7a65;
							margin-right: 3px;
						}
					}
					.el-icon-remove-outline {
						font-size: 1.5625vw;
						color: #d7d9db;
						margin-left: 10px;
					}
				}
				.avatar-uploader .el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
				}
				.avatar-uploader .el-upload:hover {
					border-color: #409eff;
				}
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width: 90px;
					height: 90px;
					line-height: 90px;
					text-align: center;
				}
				.avatar {
					width: 90px;
					height: 90px;
					display: block;
				}
			}
		}
		.update_box {
			padding: 40px 20px;
			.update_item {
				background-color: #f6f8fb;
				padding: 20px;
				a {
					color: #007aff;
					text-decoration: none;
				}
			}
			.upload-file{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.file_box{
				padding: 5px;
				.el-icon-circle-check{
					display: block;
				}
				.el-icon-circle-close{
					display: none;
				}
			}
			.file_box:hover{
				color: #007AFF;
				background-color: #F6F8FB;
				.el-icon-circle-check{
					display: none;
				}
				.el-icon-circle-close{
					display: block;
				}
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
